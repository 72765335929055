import React, { useState, useEffect } from "react";

const timeString = () => {
    return new Date().toLocaleTimeString("en-us", {hour:"numeric", minute:"2-digit"})
}

const Time = () => {
    const [time, setTime] = useState();

    useEffect(() => {
         const timer = setInterval(()=>  setTime(timeString() , 5*1000));
         return () => { clearInterval(timer) }
    });

    return (
        <div className="time"> { time } </div>
    )
}

export default Time
