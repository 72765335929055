import React from "react";

const Blank = () => {

  return (
    <div id="lowerbox" style={{position:"relative", height: '80vh', width: '100vw'}}>
      <div className="messageBox" />  
    </div>        
  );
}

export default Blank;
