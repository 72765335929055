import React from 'react';
import { Link } from 'react-router-dom';
import { connect }from 'react-redux';

class Header extends React.Component {

    renderContent() {
        const propauth = this.props.auth
        switch (propauth ){
            case null: {
                return;
            }

            case false: {
                return (<li> <a href="/auth/google" style={{fontSize:"1em"}}>Log in with Google</a> </li> );
            }
            default: {
                return [
                    <li key="2"> <a href="/api/logout" style={{fontSize:"1em"}}>Log out</a> </li>
                ];
            }
        }
    }

    render() {
        // console.log(this.props)
        return  (
            <nav>
                <div className="nav-wrapper" style={{margin:"auto"}}>
                 <Link 
                    to={this.props.auth ? "/home" : "/"}
                    className="left brand-logo"
                 >
                     Mary's Bulletin Board
                 </Link>
                 <ul className="right">
                    { this.renderContent() }
                 </ul>
                
                </div>
            </nav>
        
        )
    }
}

const mapStateToProps = state => {
  return {  auth: state.auth }
}

export default connect(mapStateToProps, null)( Header);
