import React, {  useState, useEffect } from "react";

const DateDiv = () => {

  const dateString = () => {
    const date = new Date();
    const month = date.toLocaleString('en-EN', { month: "short" })
    const day = date.toLocaleString('en-us', {day: "numeric"})
    const year = date.toLocaleString('en-us', {year: "numeric"})
    const string = month + ' ' + day + ', ' + year;
    return string
  }

  const updateDate = ()=> {
    const string = dateString();
    setDate(string); 
  };

  const [date, setDate] = useState(dateString())
  
  useEffect(() => {
        const timer = setInterval(()=>  updateDate(), 5*1000);
        return () => { clearInterval(timer) }
  });

  return (
      <div className="date"> { date } </div>
  )
}

export default DateDiv