

import React from "react";
import BulletinBoard from "../../components/BulletinBoard/BulletinBoard";
import DateDiv from "../../components/date/DateDiv";
import Time from "../../components/time/Time";
import Day from "../../components/day/Day";


function Home() {
    return (
      <div className="App">
        <div className="row grey darken-3">
          <div className="col s3">
            <DateDiv />
          </div>
          <div className="col s6">
            <Day />
          </div>
          <div className="col s3">
            <Time />
          </div>
        </div> 
        <div className="row">
            <BulletinBoard />
        </div>
      </div> 
   )
  }
  
  export default Home;
  