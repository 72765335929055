// This index.js is allow us to import reducers directory directly
//   into the higher level index.js file by just importing 'reducers'.
//   See ../index.js.

import { combineReducers } from 'redux';
import {reducer as reduxForm } from 'redux-form';
import { authReducer } from './authReducer';

// keys in the combineReducers object are the keys in the state
//   in 'state'

export default combineReducers({
    auth: authReducer,
    form: reduxForm
});

