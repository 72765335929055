import React from "react";

const Autolog = () => {

  return (
    <div id="g_id_onlodad"
       data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
       data-login_uri="https://marysactivity.com/auth/google"
       data-auto_select="true">
    </div>
  )
}

export default Autolog;


