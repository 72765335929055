import axios from 'axios';
import { FETCH_USER } from './types';
import { FETCH_SURVEYS } from '../actions/types';

// Action creaters
// ===============

// without redux-thunk:

// const fetchUser = () => {
//     const request = axios.get('/api/current_user');
//     return { 
//         type: FETCH_USER, 
//         payload: res 
//     }
// }

// using redux-thunk: get access to dispatch directly

// v1 version:
// export const fetchUser = () => {
//     return function(dispatch){
//         axios.get('/api/current_user')
//         .then(res => dispatch({ type: FETCH_USER, payload: res }))
//     }
// }

// v2 version: remove curly braces becasue there is no other logic other than return
// export const fetchUser = () => 
//     function(dispatch){
//         axios.get('/api/current_user')
//         .then(res => dispatch({ type: FETCH_USER, payload: res }))
// }

// v3 version: remove curly braces becasue there is no other logic other than return
// export const fetchUser = () => 
//     function(dispatch){
//         axios.get('/api/current_user')
//         .then(res => dispatch({ type: FETCH_USER, payload: res }))
// }

// v4 version: return an arrow function
// export const fetchUser = () => 
//     (dispatch) => {
//         axios.get('/api/current_user')
//         .then(res => dispatch({ type: FETCH_USER, payload: res }))
// }

// v5 version: single argument needs no parentheses
// export const fetchUser = () => dispatch => {
//         axios
//             .get('/api/current_user')
//             .then(res => dispatch({ type: FETCH_USER, payload: res }))
// }

// v6 version: async/await syntax
export const fetchUser = () => async dispatch => {
    const res = await axios.get('/api/current_user');
    dispatch({ type: FETCH_USER, payload: res.data });
}

export const handleToken = token => async dispatch => {
    console.log('handling token')
    const res = await axios.post('/api/stripe', token);
    console.log('stripe user update', res.data)
    dispatch({ type: FETCH_USER, payload: res.data });
}

export const sendSurvey = (values, navigate) => async dispatch => {
    console.log('sending survey')
    const res = await axios.post('/api/surveys', values);
    console.log('send survey user update', res.data)
    navigate('/surveys');
    dispatch({ type: FETCH_USER, payload: res.data });
}

export const fetchSurveys = () => async dispatch => {
    const res = await axios.get('/api/surveys');
    dispatch({ type: FETCH_SURVEYS, payload: res.data });
}
