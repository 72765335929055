// Rendering (React React-Router)
// using import because React uses ES2015 constructs

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from './actions'; // action creaters are in ../actions/index.js by convention
// BrowserRouter: brains
// Route: routing rule

import Home from "./pages/Home/home";
import SignIn from "./pages/Signin/signin";
import Header from "./components/header/Header";
import Autolog from "./components/autolog/Autolog";

import "./App.css"

class App extends React.Component  {

    componentDidMount(){
        this.props.fetchUser();
    }

    render(){
//        console.log("startup auth", this.props.auth)
//        console.log("local token", localStorage)
        localStorage.clear();
        return (
            <div>
                <BrowserRouter>  
                    <Autolog />
                    <Header />
                    <Routes>
                        <Route exact path='/' element={<SignIn />} />
                        <Route exact path='/home' element={<Home />} />
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }
};

// connect connects actions to props in this case.   Normally, we pass
//  a mapDispatchToProps function that returns functions that have been passed
//  dispatch.  Please see ../actions/index.js for how this is implemented without
//  mapDispatchToProps.
 
// connect(state, actions)

const mapStateToProps = state => ({
    auth : state.auth
})
export default connect(mapStateToProps, actions)(App);
