import React, { useState, useEffect } from "react";

const dayString = () => {
  return new Date().toLocaleDateString("en-US", { weekday: 'long' })
}

const Day = () => {
  const [day, setDay] = useState();

  useEffect(() => {
    const timer = setInterval(()=>  setDay(dayString() , 5*1000));
         return () => { clearInterval(timer) }
  });
  
  return (
      <div className="day"> {day} </div>
  );
}

export default Day;
