
import { FETCH_USER } from '../actions/types';
import { SAVE_USER } from '../actions/types';

export const authReducer =  function(state = null, action){
    switch (action.type) {
        case FETCH_USER:
            return action.payload || false;
        case SAVE_USER:
            return action.payload || false;
        default:
            return state
    }
}


