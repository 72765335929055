

import React, { useRef, useState, useEffect } from "react";
import Draggable from "react-draggable";
import { v4 as uuidv4 } from "uuid";
import { writeBBMessage, readBBMessagesArray, updatePosFirebase, deleteItem } from "../../utils/firebase";

var randomColor = require("randomcolor");

const BulletinBoard = () => {

  const [text, setText] = useState("")
  // const [item, setItem] = useState("");
  const [items, setItems] = useState([]);

  const inputRef = useRef(null);

  const loadItems = (data) =>{ 
    setItems([...data]);
  }

  useEffect(() => {
    readBBMessagesArray(loadItems)
  },[])

  const keyPress = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      newitem();
    }
  };

  const normalize = (posObj) => {
    const viewPortWidth = Number(window.innerWidth);
    const viewPortHeight = Number(window.innerHeight); 

    let x = posObj['x'];
    let y = posObj['y'];
    
    const xpos = Math.floor(x*10000/viewPortWidth);
    const ypos = Math.floor(y*10000/viewPortHeight);

    return {x: xpos, y: ypos}
  }

  const denormalize = (posObj) => {
    const viewPortWidth = Number(window.innerWidth);
    const viewPortHeight = Number(window.innerHeight); 

    let x = posObj['x'];
    let y = posObj['y'];

    x = Math.floor(x*viewPortWidth/10000)
    y = Math.floor(y*viewPortHeight/10000)

    return {x: x, y: y}
  }
  
  const newitem = () => {
    if (text.trim() !== "") {
      // find local viewport size
      const viewPortWidth = Number(window.innerWidth);
      const viewPortHeight = Number(window.innerHeight); 

      // put item in center of local viewport
      const xpos = Math.floor(viewPortWidth / 2);
      const ypos = Math.floor(viewPortHeight / 2);

      // normalize to 10000 x 10000 viewport
      const pos = normalize({x: xpos, y:ypos});
      
      console.log(`vw: ${viewPortWidth}, vh: ${viewPortHeight}, middle: ${xpos}, ${ypos},  Normalized position: ${pos}`);
      const newitem = {
        id: uuidv4(),
        item: text,
        color: randomColor({luminosity: "light",}),
        defaultPos: pos,
        position: pos,
      };
      writeBBMessage(newitem)
      setText("")
    } else {
      alert("Enter a bulletin board item");
    }
  };
  
  const updatePos = (data, index) => {   
    let newArr = [...items];
    let newX = data.x
    let newY = data.y
    // if (newX < 0 ){newX = 0}
    // if (newY < 0 ){newY = 0}
    newArr[index].defaultPos = normalize({ x: newX, y: newY });
    updatePosFirebase(newArr[index])
  };
   
  const deleteNote = (id) => {
    console.log(`deleting: ${id}`) 
    deleteItem(id)
  };


  return (
    <div id="lowerbox" style={{position:"relative", height: '90vh', width: '95vw'}}>
      <div className="messageBox">
        <input
            style={{fontSize:"1em", color:"white"}}
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter a message..."
            onKeyPress={(e) => keyPress(e)}
        />
        <button style={{fontSize:"70%"}} onClick={(e)=>newitem(e.target.value)}>ENTER</button>
      </div>
      
      {items.map((it, index) => {
        return (
          <Draggable ref={inputRef}  
            bounds="#lowerbox"
            key={it.id}
            defaultPosition={denormalize(it.defaultPos)}
            position={denormalize(it.defaultPos)}
            onStop={(e, data) => {
              updatePos(data, index);
            }}
          >
            <div style={{ backgroundColor: it.color }} className="box" >
              {`${it.item}`}
              <button style={{fontSize:"40%"}} className="topright" id="delete" onClick={()=>deleteNote(it.id)}> X </button>
            </div>
          </Draggable>
        );
      })}
    </div>
  );
}

export default BulletinBoard;
