import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import 'materialize-css/dist/css/materialize.min.css';

import { Provider } from 'react-redux'; //glue between redux and react
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';  // needs index.js file in the reducers folder.
import reduxThunk from 'redux-thunk';
import axios from 'axios';
window.axios = axios;
console.log(`dims H x W: ${window.innerHeight} x ${window.innerWidth}`)
const root = ReactDOM.createRoot(document.getElementById('root'));

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

root.render(
    <Provider store={store}>
      <App />
    </Provider>
);



